<template>
  <div class="home-help-tab">
    <div class="home-help-tab__header">
      <h2 class="home-help-tab__title">{{ data.title }}</h2>
      <span class="home-help-tab__subtitle">{{ data.subtitle }}</span>
    </div>
    <video :src="data.src" controls="controls" class="home-help-tab__video" />
  </div>
</template>

<script>
export default {
  name: "HomeHelpTab",
  props: {
    data: Object,
  },
};
</script>

<style lang="stylus">
.home-help-tab {
  background-image url("/static/images/help-bg.png")
  background-size cover
  padding 64px
  border-radius 32px
  display flex
  flex-direction column
  align-items center
  gap 32px

  &__header {
    display flex
    flex-direction column
    gap 8px
    text-align center
    max-width 448px
  }

  &__title {
    font-size 1.125em
    font-weight 700
    line-height 24px
    margin-bottom 0
    text-transform none
  }

  &__subtitle {
    line-height 24px
  }

  &__video {
    max-width 648px
    min-height 400px
    max-height 400px
    object-fit fill
    box-shadow 0 25px 50px -12px rgba(0, 0, 0, 0.25)
    border-radius 32px
  }
}
</style>
