<template>
  <div v-show="false" class="home-help">
    <div class="home-help__top">
      <span class="home-help__title">Возможности интернет-магазина</span>
      <div class="home-help__tabs">
        <button
          v-for="(tab, i) in tabs"
          :key="tab.id"
          @click="activeTab = i"
          :class="{ 'btn--hollow no-hover': activeTab === i }"
          class="btn btn--md btn--gray--outline home-help__tabs-btn"
        >
          {{ tab.title }}
        </button>
      </div>
    </div>
    <div class="home-help__bottom">
      <div v-for="(item, i) in data" :key="item.id">
        <HomeHelpTab :data="item" v-if="activeTab === i" />
      </div>
    </div>
  </div>
</template>

<script>
import HomeHelpTab from "./HomeHelpTab.vue";

export default {
  name: "HomeHelpComponent",
  components: { HomeHelpTab },
  data() {
    return {
      activeTab: 0,
      tabs: [
        {
          id: 1,
          title: "Свои наборы",
          active: true,
        },
        {
          id: 2,
          title: "Добавление товара в действующий заказ",
          active: false,
        },
        {
          id: 3,
          title: "Отложенная доставка",
          active: false,
        },
        {
          id: 4,
          title: "Оценка заказа",
          active: false,
        },
      ],
      data: [
        {
          title: "Добавление товара в действующий заказ",
          subtitle:
            "За час до выбранного времени доставки, у тебя есть возможность дополнить заказ, если чего-то не хватило. ",
          // src: "/static/video/help-video-1.mp4",
        },
        {
          title: "Добавление товара в действующий заказ тест2",
          subtitle:
            "За час до выбранного времени доставки, у тебя есть возможность дополнить заказ, если чего-то не хватило. тест2",
          // src: "/static/video/help-video-1.mp4",
        },
        {
          title: "Добавление товара в действующий заказ тест3",
          subtitle:
            "За час до выбранного времени доставки, у тебя есть возможность дополнить заказ, если чего-то не хватило. тест3",
          // src: "/static/video/help-video-1.mp4",
        },
        {
          title: "Добавление товара в действующий заказ тест4",
          subtitle:
            "За час до выбранного времени доставки, у тебя есть возможность дополнить заказ, если чего-то не хватило. тест4",
          // src: "/static/video/help-video-1.mp4",
        },
      ],
    };
  },
};
</script>

<style lang="stylus">
.home-help {
  display flex
  flex-direction column
  gap 32px

  &__title {
    font-size 2em
    font-weight 400
    line-height 38px
    text-align center
  }

  &__tabs {
    display flex
    gap 16px
  }

  &__tabs-btn {
    font-size 0.875em
    line-height 140%
    padding 8px 16px
    height 38px

    &.no-hover {
      cursor default
      border none
    }
  }

  &__top {
    display flex
    flex-direction column
    align-items center
    gap 33px
  }

  & .btn--gray--outline--active {
    color var(--main)
    border-color var(--main)

    .icon svg {
      path {
        fill var(--main)
      }
    }
  }
}
</style>
